import axios from 'axios';
import Vue from 'vue'

// 通用公用方法
let baseUrl = "http://39.100.231.120:28080"
// baseUrl = "https://api-pre.yansi.cn"
if (window.location.host == "yansi.cn" || window.location.host == 'www.yansi.cn') {
    baseUrl = 'https://api.yansi.cn'
}
if (window.location.host == 'pre.yansi.cn') {
    baseUrl = "https://api-pre.yansi.cn"
}
if (window.location.host == 'uat.yansi.cn') {
    baseUrl = "https://api-uat.yansi.cn"
}
if (window.location.host == '39.100.231.120') {
    baseUrl = "http://39.100.231.120:28080"
}
const req = (method, url, params) => {
    return axios({
        method: method,
        url: baseUrl + url, // 测试
        headers: {
            'Content-Type': 'application/json;charset:utf-8',
            Authorization: window.localStorage.getItem('token')
        },
        data: params,
    }).then(res => {
        if (res.status === 200) {
            if (res.data.code == 401) {
                Vue.prototype.$message.error("登录状态已过期，请重新登录。");
                if (
                    window.location.host == "yansi.cn" ||
                    window.location.host == "www.yansi.cn" || window.location.host == 'pre.yansi.cn' || window.location.host == "uat.yansi.cn"
                ) {
                    window.location.href = `https://${window.location.host}/#/`;
                } else if (window.location.host == 'localhost:8080') {
                    window.location.href = `http://${window.location.host}/#/`;
                } else {
                    window.location.href = `http://${window.location.host}/expert/#/`;
                }
                return
            }
            return res.data
        } else {
            return false
        }
    });
};

export default req