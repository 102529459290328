<template>
  <div class="header">
    <div class="leftBox">
      <a
        href="javascript:;"
        @click="$router.push('/index')"
        style="color: rgba(67, 92, 119, 1); font-size: 14px"
      >
        <img src="../assets/headerLogo.png" alt="" />
        <!-- <span>研斯论文评审平台</span> -->
      </a>
    </div>
    <div class="leftBox rightBox">
      <!-- <img src="../assets/123123.png" alt="" /> -->
      <a-avatar
        shape="square"
        size="large"
        :style="{ backgroundColor: '#3d81c9', verticalAlign: 'middle' }"
      >
        {{ user.name }}
      </a-avatar>
      <a-dropdown>
        <a class="ant-dropdown-link">
          <span class="name">{{ user.name }}</span>
          <img
            style="width: 20px; height: 20px"
            src="../assets/jtx.png"
            alt=""
          />
        </a>
        <a-menu slot="overlay">
          <!-- <a-menu-item @click="isShowLogoutDialog = true"> -->
          <a-menu-item @click="checkIcon">
            <a href="javascript:;">退出登录</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-modal
      :visible="isShowLogoutDialog"
      @ok="sureLogout"
      okText="确认"
      cancelText="取消"
      @cancel="isShowLogoutDialog = false"
      :cancel-button-props="{ style: { display: 'none' } }"
      :keyboard="false"
      :closable="false"
      width="300px"
      :maskClosable="false"
      :footer="null"
    >
      <!-- <div class="modalHeader">
        <div class="title" style="color: #435c77">退出登录</div>
        <div class="closeIcon" @click="isShowLogoutDialog = false"></div>
      </div> -->
      <div class="dialogMain">
        <div class="main">
          <a-icon type="question-circle" style="color: #faad14" />
          <span style="margin-left: 10px">你确认要退出登录吗？</span>
        </div>
      </div>
      <div class="modalFooter">
        <a-button @click="isShowLogoutDialog = false">关闭</a-button>
        <a-button @click="sureLogout" class="okBtn" type="danger"
          >确认</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";
export default {
  data() {
    return {
      user: {},
      isShowLogoutDialog: false,
    };
  },
  methods: {
    checkIcon(e) {
      // console.log(e);
      let that = this;
      Modal.confirm({
        title: "你确认要退出登录吗？",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          that.$http("post", "/logout").then((res) => {
            // console.log(res);
            if (res.code == 200) {
              that.$message.success("退出成功");
              window.localStorage.setItem("token", "");
              that.$router.push("/");
            }
          });
        },
      });
    },
    sureLogout() {
      this.$http("post", "/logout").then((res) => {
        if (res.code == 200) {
          this.$message.success("退出成功");
          window.localStorage.setItem("token", "");
          this.$router.push("/");
        }
      });
    },
  },
  created() {
    this.$http("get", "/getInfo").then((res) => {
      this.$store.state.userMsg = res.user;
      this.user = res.user;
    });
  },
};
</script>

<style lang='scss' scoped>
.header {
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  .leftBox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    line-height: 32px;
    img {
      position: relative;
      top: -2px;
      // width: 150px;
      height: 32px;
    }
    span {
      margin-left: 18px;
    }
    font-size: 18px;
    font-weight: 700;
    color: rgba(67, 92, 119, 1);
  }
  .rightBox {
    right: 50px;
    span {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .name {
      font-size: 14px;
      color: rgba(67, 92, 119, 1);
      margin-right: 5px;
    }
  }
}

.dialogMain {
  position: relative;
  text-align: left;
  font-size: 16px;
  padding: 20px 24px;
  .main {
    text-align: center;
  }
}

.modalHeader {
  position: relative;
  height: auto;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  .title {
    width: 80%;
    font-size: 14px;
    color: #435c77;
    padding: 10px 20px;
  }
  .btnBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    .dialogBtn {
      display: inline-block;
      width: 108px;
      height: 30px;
      border-radius: 4px;
      background: #3d81c9;
      // box-shadow: 0px 4px 12px 0px rgba(61, 129, 201, 0.51);
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      margin-left: 40px;
    }
  }
  .closeIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(../assets/guanbi.png);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
:deep .ant-modal-body {
  padding: 0;
}
.modalFooter {
  // border-top: 1px solid #ccc;
  text-align: center;
  padding: 20px 0;
  .okBtn {
    margin-left: 50px;
  }
}
</style>